<template>
  <div class="grid">
    <div class="col-12">
      <div class="font-medium text-3xl text-900 mb-3">
        <span class="text-primary text-5xl">/</span> {{ $route.meta.title }}
      </div>
      <div class="text-500 mb-5">Preencha os campos abaixo</div>

      <div class="card p-fluid w-full">

        <Toast />

        <div class="grid formgrid">
          <div class="field col-8">
            <label for="descricao">Descrição</label>
            <InputText id="descricao" v-model.trim="form.descricao" required="true" autofocus autocomplete="off"
              :class="{ 'p-invalid': submitted && !form.descricao }" />
            <small class="p-error" v-if="submitted && !form.descricao">Descrição é obrigatório.</small>
          </div>

          <div class="field col-4">
            <label for="ativo">Situação</label>
            <Dropdown id="ativo" v-model="form.ativo" :options="optionsAtivoInativo" optionLabel="label"
              optionValue="value" placeholder="Situação da carteira">
            </Dropdown>
          </div>

          <div class="field col-6">
            <label for="cliente">Cliente</label>
            <Dropdown id="cliente" v-model="form.cliente" :options="clients" optionLabel="razaoSocial" dataKey="id"
              placeholder="Selecione o cliente" :class="{ 'p-invalid': submitted && !form.cliente }"></Dropdown>
            <small class="p-error" v-if="submitted && !form.cliente">Cliente é obrigatório.</small>
          </div>

          <div class="field col-6">
            <label for="politicaNegociacao">Política de negociação</label>
            <Dropdown id="politicaNegociacao" v-model="form.politicaNegociacao" :options="policies"
              optionLabel="descricao" dataKey="id" placeholder="Selecione a política de negociação"
              :class="{ 'p-invalid': submitted && !form.politicaNegociacao }"></Dropdown>
            <small class="p-error" v-if="submitted && !form.politicaNegociacao">Política de negociação é
              obrigatória.</small>
          </div>
        </div>

        <div class="flex mt-5">
          <Button label="Salvar" icon="pi pi-check" class="p-button-primary w-6" @click="save" />
          <Button label="Voltar" icon="pi pi-times" class="p-button-text w-3 ml-auto"
            @click="$router.push('/carteiras')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CrudService from '@/services/crud'

export default {
  data() {
    return {
      form: {
        ativo: true
      },
      submitted: false,
      optionsAtivoInativo: [
        { value: true, label: 'Ativo' },
        { value: false, label: 'Inativo' }
      ],
      clients: [],
      policies: []
    }
  },
  async mounted() {
    this.service = new CrudService('/admin/carteiras')
    this.serviceClients = new CrudService('/admin/clientes')
    this.servicePolitcs = new CrudService('/admin/politica-negociacao')

    this.loadClients();
    this.loadPolitcs();

    if (this.$route.params.id) {
      const { data } = await this.service.findById(this.$route.params.id)

      this.form = data
      let cliente = data.cliente
      this.form.idCliente = cliente != null ? cliente.id : null
    }
  },
  methods: {
    async loadClients() {
      const { data } = await this.serviceClients.findAll();
      this.clients = data.items;
    },
    async loadPolitcs() {
      const { data } = await this.servicePolitcs.findAll();
      this.policies = data.items;
    },
    async save() {
      this.submitted = true
      try {
        await this.service.save(this.form)
        this.$toast.add({
          severity: 'success',
          summary: 'Carteira gravada com sucesso!',
          life: 3000
        })
        this.$router.push('/carteiras')
      } catch (err) {
        this.$toast.add({
          severity: 'error',
          summary: 'Problemas ao armazenar o carteira!',
          life: 3000
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
